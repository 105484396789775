import { observer } from "mobx-react-lite";
import React from "react";
import { ConfirmationCheck } from "src/icons/coreIcons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

type Props = {};

const ConfirmationComponent = observer(
  ({
    clearDataAfterConfirmation,
    openDialog,
  }: {
    clearDataAfterConfirmation: any;
    openDialog: boolean;
  }) => {
    return (
      <Dialog
        open={openDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent sx={{ backgroundColor: "#222222" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="confirmation-cancel-btn"
              style={{ alignSelf: "flex-end" }}
              onClick={() => clearDataAfterConfirmation()}
            >
              &#x2715;
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div style={{ padding: "30px 0px", textAlign: "center" }}>
                <DialogContentText
                  id="alert-dialog-description"
                  variant="h5"
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    marginBottom: "25px",
                  }}
                >
                  Your custom itinerary has been submitted for a quote
                </DialogContentText>
                <p
                  className="content-text-second"
                  style={{ color: "#fff", fontSize: "15px" }}
                >
                  Our team will get back to you shortly to move this forward
                </p>
              </div>
              <button
                className="confirmation-okay-btn"
                style={{
                  color: "white",
                  marginBottom: "12px",
                  padding: "12px 25px",
                }}
                onClick={() => clearDataAfterConfirmation()}
              >
                Ok
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ConfirmationComponent;
