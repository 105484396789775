import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  logout,
  getUserDetailsFromFirestore,
} from "../../helpers/firebase";
import "./Header.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import userIcon from "../../assets/images/user-icon.png";
import dropdownIcon from "../../assets/images/drop-down-icon.png";
import locationIcon from "../../assets/images/location-icon.png";
import searchIcon from "../../assets/images/search-icon.png";
import closeIcon from "../../assets/images/search-close.png";
import awsConfig from "../../configs/aws";
import { Chip, Stack } from "@mui/material";
import itineraryPlanStore from "./../../store/itineraryPlanStore";
import { observer } from "mobx-react-lite";
import MuiValidationDialogComponent from "src/containers/validationComponent/MuiValidationDialogComponent";

const Header = observer(() => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [loginPath, setLoginPath] = useState("/login");
  const [isPartnerSite, setIsPartnerSite] = useState(false);
  const [partnerUserData, setPartnerUserData] = useState(null);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  window.addEventListener("storage", () => {
    const partnerUser = sessionStorage.getItem("partnerUser");
    if (partnerUser) {
      setPartnerUserData(JSON.parse(partnerUser));
      setIsPartnerSite(true);
    }
  });

  useEffect(() => {
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
      setLoginPath("/partner-login");
    }
  }, []);

  useEffect(() => {
    const partnerUserVal = JSON.parse(
      `${sessionStorage.getItem("partnerUser")}`
    );
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true" &&
      partnerUserData == null
    ) {
      setPartnerUserData(partnerUserVal);
      setIsPartnerSite(true);
    }
  });

  useEffect(() => {
    if (user && user.accessToken) {
      getUserDetailsFromFirestore().then((data) => {
        data.forEach((doc) => {
          sessionStorage.setItem("email", doc.data()?.email);
        });
      });
    }
  }, [user]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    removeSessionStorage();
    if (searchQuery) {
      navigate(`/search/${searchQuery}/page1`);
      if (getAnalyticsEnabled()) {
        ReactGA.event({
          action: `${window.location.pathname}_search_query`,
          category: `search`,
        });
      }
    }
  };

  const navigateToScreen = (item) => {
    setShowMobileMenu(false);
    navigate(item, { replace: true });
  };

  const removeSessionStorage = () => {
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("showListCount");
    sessionStorage.removeItem("selectedSortItem");
    sessionStorage.removeItem("currentPage");
    sessionStorage.removeItem("filterListData");
    sessionStorage.removeItem("homePageScrollPosition");
    sessionStorage.removeItem("detailPropertyData");
    sessionStorage.removeItem("selectedSortParam");
    sessionStorage.removeItem("footerScrollPostion");
  };

  const closeSortMenu = () => {
    sessionStorage.setItem("closeSortMenu", true);
  };

  const userLogout = () => {
    localStorage.removeItem("voyaahCustomerLoggedIn");
    removeSessionStorage();
    sessionStorage.removeItem("partnerUser");
    setPartnerUserData(null);
    logout();
    if (isPartnerSite) {
      navigate("/partner-login");
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("partnerUser");
    sessionStorage.removeItem("email");
  };

  useEffect(() => {
    if (!itineraryPlanStore.isItenaryStaysMount) {
      itineraryPlanStore.setIsItenaryStaysMount(true);
    }
  }, [location.pathname]);

  const handleReceiveData = (data) => {
    if(typeof data === "boolean"){
      setOpenLoginDialog(data)
    }
    else{
      setOpenLoginDialog(data.closeDialog)
    }
  }

  return (
    <>
      <div
        onClick={() => closeSortMenu()}
        className={`${
          itineraryPlanStore.isItenaryStaysMount ||
          location.pathname.includes("/custom-itinerary")
            ? "non-sticky"
            : "is-sticky"
        }`}
      >
        <div className="header-wrapper">
          <div
            className={`section header ${
              showMobileMenu ? "hide-mobile-menu" : "show-mobile-menu"
            }`}
          >
            <div
              className="mobile-menu-icon"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <img
                src={require("../../assets/images/mobile-menu-icon.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div
              className="mobile-search-icon"
              onClick={() => setShowMobileSearch(!showMobileSearch)}
            >
              <img
                src={require(`../../assets/images/${
                  showMobileSearch ? "close" : "mobile-search-icon"
                }.png`)}
                alt=""
                className="img-fluid"
              />
            </div>
            {showMobileSearch && (
              <div className="search-input">
                <div className="search search-mobile">
                  <div className="location-icon">
                    <img src={locationIcon} alt="" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter a destination or Property"
                    name="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="search-icon">
                    <img src={searchIcon} alt="" onClick={handleSearch} />
                  </div>
                </div>
              </div>
            )}
            <div className="logo">
              <NavLink to="/">
                <img
                  src={awsConfig.s3Path + "logo_transparent.png"}
                  alt="Voyaah.com"
                  className="img-fluid"
                />
              </NavLink>
            </div>
            <div className="menu">
              <ul>
                <li onClick={() => removeSessionStorage()}>
                  <NavLink to="/staycations/page1">Staycations</NavLink>
                </li>
                <li onClick={() => removeSessionStorage()}>
                  <NavLink to="/holidays/page1">Holidays</NavLink>
                </li>
                <li
                  onClick={() => removeSessionStorage()}
                  style={{ position: "relative" }}
                >
                  <span
                    style={{
                      borderRadius: "10px",
                      position: "absolute",
                      top: "8px",
                      width: "70%",
                      textAlign: "center",
                      color: "#1AB2D2",
                      fontSize: "10px",
                      fontWeight: "600",
                      left: "45%",
                    }}
                  >
                    Now Live
                  </span>

                  <NavLink to="/custom-itinerary">Custom Itinerary</NavLink>
                </li>
                <li onClick={() => removeSessionStorage()}>
                  <NavLink to="/group-booking">Group Booking</NavLink>
                </li>
                <li onClick={() => removeSessionStorage()}>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
              </ul>
            </div>

            {!isPartnerSite && user ? (
              <div className="right-menu">
                <ul>
                  <li>
                    <Link to="/my-profile">
                      {user?.photoURL && (
                        <img className="profile" src={user?.photoURL} alt="" />
                      )}
                      {!user?.photoURL && (
                        <img className="profile" src={userIcon} alt="" />
                      )}
                      <img src={dropdownIcon} alt="" />
                    </Link>
                    <ul className="sub-menu">
                      {user && (
                        <li>
                          <Link to="/my-profile">Profile</Link>
                        </li>
                      )}
                      <li>
                        <span onClick={userLogout}>Logout</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ) : !isPartnerSite && user == null ? (
              <div className="right-menu">
                <ul>
                  <li
                    onClick={() => {
                      // navigate(
                      //   process.env.REACT_APP_PARTNER_SITE === "true" &&
                      //     process.env.REACT_APP_PARTNER_SITE
                      //     ? "/partner-login"
                      //     : loginPath,
                      //   { state: { from: location.pathname } }
                      // );
                      setOpenLoginDialog(true);
                    }}
                  >
                    <span className="link">Login</span>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {isPartnerSite && partnerUserData?.uid ? (
              <div className="right-menu">
                <ul>
                  <li>
                    <Link to="/my-profile">
                      {user?.photoURL && (
                        <img className="profile" src={user?.photoURL} alt="" />
                      )}
                      {!user?.photoURL && (
                        <img className="profile" src={userIcon} alt="" />
                      )}
                      <img src={dropdownIcon} alt="" />
                    </Link>
                    <ul className="sub-menu">
                      {user && (
                        <li>
                          <Link to="/my-profile">Profile</Link>
                        </li>
                      )}
                      <li>
                        <span onClick={userLogout}>Logout</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ) : isPartnerSite ? (
              <div className="right-menu">
                <ul>
                  <li>
                    <Link to="/my-profile">
                      <span style={{ color: "white" }} className="profile">
                        Site
                      </span>
                      <img src={dropdownIcon} alt="" />
                    </Link>
                    <ul style={{ width: "190px" }} className="sub-menu">
                      <li>
                        <a href={`${baseUrl}/register`}>Register with Voyaah</a>
                      </li>
                      <li>
                        <a
                          onClick={() => clearSessionStorage()}
                          href={`${baseUrl}`}
                        >
                          Exit
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ) : (
              // !isPartnerSite &&
              // user && (
              // 	<div className="right-menu">
              // 		<ul>
              // 			<li>
              // 				<Link to="/my-profile">
              // 					<span style={{ color: "white" }} className="profile">
              // 						Site
              // 					</span>
              // 					<img src={dropdownIcon} alt="" />
              // 				</Link>
              // 				<ul style={{ width: "190px" }} className="sub-menu">
              // 					<li>
              // 						<a href="https://voyaah.com/register/">
              // 							Register with Voyaah
              // 						</a>
              // 					</li>
              // 					<li>
              // 						<a href="https://voyaah.com/">Exit</a>
              // 					</li>
              // 				</ul>
              // 			</li>
              // 		</ul>
              // 	</div>
              // )
              ""
            )}

            <div className="search">
              <div className="location-icon">
                <img src={locationIcon} alt="" />
              </div>
              <input
                type="text"
                placeholder="Enter a destination or Property"
                name="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="" onClick={handleSearch} />
              </div>
              {searchQuery && (
                <div className="close-icon">
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={() => setSearchQuery("")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`mobile-menu-overlay ${
            !showMobileMenu ? "hide-mobile-menu" : "show-mobile-menu"
          }`}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        ></div>
        <div
          className={`mobile-menu-close ${
            !showMobileMenu ? "hide-mobile-menu" : "show-mobile-menu"
          }`}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <img
            src={require("../../assets/images/close-icon.png")}
            alt=""
            className="img-fluid"
          />
        </div>
        <div
          className={`mobile-menu ${
            !showMobileMenu ? "hide-mobile-menu" : "show-mobile-menu"
          }`}
        >
          <ul>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/");
              }}
            >
              Home
            </li>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/staycations/page1");
              }}
            >
              <NavLink to="/staycations/page1">Staycations</NavLink>
            </li>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/holidays/page1");
              }}
            >
              <NavLink to="/holiday/page1">Holidays</NavLink>
            </li>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/custom-itinerary");
              }}
            >
              <NavLink to="/custom-itinerary">Custom Itinerary</NavLink>
            </li>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/group-booking");
              }}
            >
              Group Booking
            </li>
            <li
              onClick={() => {
                removeSessionStorage();
                navigateToScreen("/about-us");
              }}
            >
              About us
            </li>
            {(user || (isPartnerSite && partnerUserData)) && (
              //{isPartnerSite && partnerUserData?.uid && (
              <>
                {user && (
                  <li
                    onClick={() => {
                      removeSessionStorage();
                      navigateToScreen("/my-profile");
                    }}
                  >
                    Profile
                  </li>
                )}
                <li onClick={userLogout}>Logout</li>
              </>
            )}
            {!user && !partnerUserData && (
              // {!partnerUserData?.uid && (
              <>
                <li onClick={() => navigateToScreen(loginPath)}>Login</li>
              </>
            )}
          </ul>
        </div>
      </div>
      {openLoginDialog && (
        <MuiValidationDialogComponent
          openDialog={openLoginDialog}
          clickAway={true}
          isAuth = {false}
          email={""}
          handlerFun={handleReceiveData}
        />
      )}
    </>
  );
});

export default Header;
