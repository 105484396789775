import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CustomItenaryLogin from "../customItinerary/CustomItinaryLogin";
import { useNavigate } from "react-router-dom";
import { guestSignIn } from "../../helpers/firebase";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MuiValidationDialogComponent(props: any) {
  const { openDialog, handlerFun, clickAway, email,isAuth } = props;
  const navigate = useNavigate();

  const signInasGuestEmail = () => {
    sessionStorage.setItem("email", email);
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      keepMounted
      fullWidth={true}
      onClose={() => {
        if (clickAway) {
          handlerFun(false);
        }
      }}
      maxWidth="sm"
      PaperProps={{
        sx: {
          bgcolor: "#222222",
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ backgroundColor: "#222222", position: "relative" }}>
        <div className="dialog-login">
          <CustomItenaryLogin handler={handlerFun} />
          {isAuth && (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <span
                style={{
                  cursor: "pointer",
                  color: "#2E8B98",
                  fontWeight: "700",
                }}
                onClick={() => {
                  guestSignIn();
                  signInasGuestEmail();
                }}
              >
                Checkout as Guest
              </span>
            </div>
          )}
        </div>

  {/* dialog close button */}
        <button
          className="confirmation-cancel-btn"
          style={{ position: "absolute", right: 15, top: 15 }}
          onClick={() => {
            clickAway ? handlerFun(false) : navigate("/custom-itinerary");
          }}
        >
          &#x2715;
        </button>
      </DialogContent>
    </Dialog>
  );
}
