import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const ClearConfirmationComponent = observer(
  ({
    closeModal,
    clearContents,
    openDialog,
  }: {
    closeModal: any;
    clearContents: any;
    openDialog: boolean;
  }) => {
    // custom dialog old jsx
    // <div className="confirmation-overlay">
    //   <div className="confirmation-popup">
    //     <div className="confirmation-cancel">
    //       <button
    //         className="confirmation-cancel-btn"
    //         onClick={() => closeModal(false)}
    //       >
    //         &#x2715;
    //       </button>
    //     </div>
    //     <div className="confirmation-content">
    //       <div className="confirmation-content-text">
    //         <p className="content-text-first">
    //           All destination data, dates and stays information will be cleared,
    //           are you sure you want to clear?
    //         </p>
    //       </div>
    //     </div>
    //     <div className="validation-btn-div">
    //       <button
    //         className="confirmation-okay-btn"
    //         style={{ color: "white" }}
    //         onClick={() => {
    //           clearContents();
    //           closeModal(false);
    //         }}
    //       >
    //         Proceed
    //       </button>
    //       <button
    //         className="confirmation-okay-btn"
    //         style={{ color: "white" }}
    //         onClick={() => closeModal(false)}
    //       >
    //         Cancel
    //       </button>
    //     </div>
    //   </div>
    // </div>;
    //
    return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent sx={{ backgroundColor: "#222222" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="confirmation-cancel-btn"
              style={{ alignSelf: "flex-end" }}
              onClick={() => closeModal(false)}
            >
              &#x2715;
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <DialogContentText
                id="alert-dialog-description"
                variant="h5"
                sx={{ color: "#fff", textAlign: "center", padding: "50px 0px" }}
              >
                All destination data, dates and stays information will be
                cleared, are you sure you want to clear?
              </DialogContentText>
              <div style={{display:'flex',gap:20}}>
                <button
                  className="confirmation-okay-btn"
                  style={{
                    color: "white",
                    marginBottom: "12px",
                    padding: "12px 25px",
                  }}
                  onClick={() => {
                    clearContents();
                    closeModal(false);
                  }}
                >
                  Proceed
                </button>
                <button
                  className="confirmation-okay-btn"
                  style={{
                    color: "white",
                    marginBottom: "12px",
                    padding: "12px 25px",
                  }}
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ClearConfirmationComponent;
