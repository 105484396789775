import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import moment from "moment";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";
import { addDays } from "date-fns";
import {
  findMissingDates,
  formatMoney,
  toCamelCase,
} from "../../helpers/utils";

import {
  getPackagePricing,
  clearPackagePricing,
} from "../../store/details/actions";

import itineraryPlanStore from "./../../store/itineraryPlanStore";
import { getTestEnabled } from "../../helpers/backend_helper";
import "./Details.scss";
import { toJS } from "mobx";
import { experienceApiAxios } from "src/helpers/api_helper";
import { GET_PACKAGE_AVAILABILITY } from "src/helpers/url_helper";
import { getHeader } from "src/helpers/backend_helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./StickyHeader.scss";

const StickyHeaderNew = observer((props: any) => {
  const {
    packageData,
    selectedRoomType,
    stateData,
    urlCouponCode,
    availabilityData,
  } = props;
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [DayDiff, setDayDiff] = useState<any>();
  const [adultCount] = useState(8);
  const [childCount] = useState(8);
  const [maxAdultsAllowed, setMaxAdultsAllowed] = useState(0);
  const [maxChildrenAllowed, setMaxChildrenAllowed] = useState(0);
  const [maxPersonsAllowed, setMaxPersonsAllowed] = useState(0);
  const [selectedAdultCount, setSelectedAdultCount] = useState<any>(2);
  const [selectedChildrenCount, setSelectedChildrenCount] = useState<any>(0);
  const [selectedRoomCount, setSelectedRoomCount] = useState<any>(1);
  const [maxChildAge, setMaxChildAge] = useState<any>(17);
  const [fare, setFare] = useState<any>(0);
  const [property, setProperty] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [packageDuration, setPackageDuration] = useState(0);
  const [userCouponCode, setUserCouponCode] = useState("");
  const [userCouponCodeErrorMessage, setUserCouponCodeErrorMessage] =
    useState("");
  const [userCouponDetails, setUserCouponDetails] = useState(false);
  const [childData, setChildData] = useState<any>([]);
  const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useState(false);
  const [isCouponBtnEnabled, setIsCouponBtnEnabled] = useState(false);
  const [isAvailabilityChecked, setIsAvailabilityChecked] = useState(false);
  const [openTravellerMenu, setOpenTravellerMenu] = useState<boolean>(false);
  const checkInDateRef = useRef<ReactDatePicker>(null);
  const checkOutDateRef = useRef<ReactDatePicker>(null);

  const [includeDates, setIncludeDates] = useState<any[]>([]);
  const [includeDatesClone, setIncludeDatesClone] = useState<any[]>([]);
  const [showIncludeDates, setShowIncludeDates] = useState(true);
  const [includeDateIntervals, setIncludeDateIntervals] = useState<any[]>([]);
  const [isCouponCodeSuccess, setIsCouponCodeSuccess] = useState(false);

  const [cta, setCta] = useState<any[]>([]);
  const [ctd, setCtd] = useState<any[]>([]);
  const [stopSell, setStopSell] = useState<any[]>([]);

  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [lastDateOfInventory, setLastDateOfInventory] = useState(false);
  const [inventoryFetchDays] = useState(89);
  const [isPartnerSite, setIsPartnerSite] = useState<boolean>(false);
  const [pointsEarnedFormat, setPointsEarnedFormat] = useState<string>("");

  const [totalPricing, setTotalPricing] = useState<any>({
    extraAdult: 0,
    extraAdultCost: 0,
    extraChildrenCost: 0,
    extraChildren: 0,
    totalFare: 0,
  });

  const { packageAvailability, packageAvailabilityLoader } = useSelector(
    (state: any) => state.PackageAvailabilityReducer
  );

  const { roomDetails } = useSelector((state: any) => state.RoomDetailReducer);

  const isTestingEnabled = getTestEnabled();
  const isAnalyticsEnabled = getAnalyticsEnabled();
  useEffect(() => {
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
    }

    if (process.env.REACT_APP_POINT_EARNED_FORMAT) {
      setPointsEarnedFormat(process.env.REACT_APP_POINT_EARNED_FORMAT);
    }
    return () => {
      dispatch(clearPackagePricing());
      setUserCouponCode("");
    };
  }, []);

  useEffect(() => {
    const detailPropertyData = JSON.parse(
      `${sessionStorage.getItem("detailPropertyData")}`
    );

    if (packageData?.type === "packageType.Holiday") {
      const a = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.lastDateOfInventory) {
            setLastDateOfInventory(true);
          }
          if (a.available === true) {
            return new Date(a.date).setHours(0, 0, 0, 0);
          }
        })
      );

      if (a.length > 0) {
        setShowIncludeDates(true);
      } else {
        setShowIncludeDates(false);
      }
      setIncludeDates(includeDates.concat(a).sort());
      setIncludeDatesClone(includeDatesClone.concat(a).sort());

      if (detailPropertyData?.calenderInterVal) {
        setIncludeDates(detailPropertyData?.calenderInterVal);
        setIncludeDatesClone(detailPropertyData?.calenderInterVal);
      }

      const b = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.cta) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCta(cta.concat(b));
      let startDate = new Date(packageData?.travelValidFrom);
      // if (startDate < new Date()) {
      //   startDate = new Date();
      // }
      let endDate = new Date(packageData?.travelValidTo);
      const updatedLastDate = new Date(
        packageAvailability[packageAvailability?.length - 1]?.date
      );

      if (endDate < updatedLastDate) {
        endDate = updatedLastDate;
      }

      if (detailPropertyData?.calenderInterVal) {
        endDate = new Date(
          detailPropertyData?.calenderInterVal[
            detailPropertyData?.calenderInterVal.length - 1
          ]
        );
      }

      setIncludeDateIntervals([{ start: startDate, end: endDate }]);
    } else if (packageData?.type === "packageType.Staycation") {
      const a = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.lastDateOfInventory) {
            setLastDateOfInventory(true);
          }
          if (a.available === true) {
            return new Date(a.date).setHours(0, 0, 0, 0);
          }
        })
      );
      if (a.length > 0) {
        setShowIncludeDates(true);
      } else {
        setShowIncludeDates(false);
      }

      setIncludeDates(includeDates.concat(a).sort());
      setIncludeDatesClone(includeDatesClone.concat(a).sort());

      if (detailPropertyData?.calenderInterVal) {
        setIncludeDates(detailPropertyData?.calenderInterVal);
        setIncludeDatesClone(detailPropertyData?.calenderInterVal);
      }

      const b = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.cta) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCta(cta.concat(b));
      // setCta([new Date("2022-12-20T12:00:00+00:00").toDateString()]);

      const c = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.ctd) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCtd(ctd.concat(c));
      // setCtd([new Date("2022-12-23T12:00:00+00:00").toDateString()]);

      const d = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.stopSell) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setStopSell(stopSell.concat(d));
      // setStopSell([
      //   new Date("2023-01-06T12:00:00+00:00").toDateString(),
      //   new Date("2023-01-10T12:00:00+00:00").toDateString(),
      // ]);
    }
  }, [packageAvailability, roomDetails.id]);

  useEffect(() => {
    const childDataSize = _.size(childData);
    const selectedChildCount = parseInt(selectedChildrenCount);
    if (selectedChildCount === 0) {
      setChildData([]);
    } else if (selectedChildCount > childDataSize) {
      const childDataObj = _.range(selectedChildCount - childDataSize).map(
        (item: any) => {
          return {
            id: `child_${childDataSize + item + 1}_age`,
            title: `Child ${childDataSize + item + 1} Age`,
            age: "1",
          };
        }
      );
      setChildData(childData.concat(childDataObj));
    } else if (selectedChildCount < childDataSize) {
      let childDataObj = [...childData];
      childDataObj.splice(
        childDataObj.length - (childDataSize - selectedChildCount)
      );
      setChildData(childDataObj);
    }
  }, [selectedChildrenCount]);

  useEffect(() => {
    const data = {
      packageData: packageData,
      startDate: startDate ? moment(startDate).format("MM-DD-YYYY") : "",
      endDate: endDate ? moment(endDate).format("MM-DD-YYYY") : "",
      noOfRooms: selectedRoomCount,
      noOfAdults: selectedAdultCount,
      noOfChilds: selectedChildrenCount,
      roomType: selectedRoomType,
      dayDiff: DayDiff,
      couponCode: `${userCouponCode ? userCouponCode : ""}`,
      vendorName: `${userCouponCode ? "newvoyaah" : ""}`,
      childData: childData,
    };
    setProperty(data);
  }, [
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    DayDiff,
    userCouponCode,
    childData,
  ]);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      selectedAdultCount &&
      selectedChildrenCount >= 0 &&
      selectedRoomCount
    ) {
      setIsContinueBtnEnabled(true);
    }
  }, [
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
  ]);

  useEffect(() => {
    if (!stateData) {
      if (userCouponCode) {
        if (isAvailabilityChecked) {
          setIsCouponBtnEnabled(true);
          setIsContinueBtnEnabled(false);
        } else if (!isAvailabilityChecked) {
          setUserCouponCodeErrorMessage(
            "Please check availability before applying coupon"
          );
        }
        // }
      } else {
        setUserCouponCodeErrorMessage("");
        setIsCouponBtnEnabled(false);
        if (isAvailabilityChecked) {
          setIsContinueBtnEnabled(true);
        } else if (
          !isAvailabilityChecked &&
          startDate &&
          endDate &&
          selectedAdultCount &&
          selectedChildrenCount >= 0 &&
          selectedRoomCount
        ) {
          setIsContinueBtnEnabled(false);
        } else {
          setIsContinueBtnEnabled(false);
        }
      }
    }
  }, [userCouponCode]);

  const countStartDays = (date: any, dayIndexTo: any) => {
    let startDate = moment(date, "YYY-MM-DD");
    if (dayIndexTo != 1) {
      startDate.add(dayIndexTo - 1, "days");
    }
    let formattedDate = startDate.format("YYYY-MM-DD");
    return formattedDate;
  };

  const getDateStrInYYYYMMDD = (date: Date) => {
    if (!date) {
      return "";
    }

    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const countEndDays = (date: any, dayIndexTo: any) => {
    let startDate = moment(date, "YYY-MM-DD");
    if (dayIndexTo != 1) {
      startDate.add(dayIndexTo, "days");
    }
    let formattedDate = startDate.format("YYYY-MM-DD");
    return formattedDate;
  };

  const handleCheckAvailabilityClick = useCallback(() => {
    if (
      packageData &&
      packageData.id &&
      startDate &&
      endDate &&
      selectedRoomCount &&
      itineraryPlanStore.stayRoomTypes.length > 0 &&
      selectedAdultCount &&
      ((packageData.type === "packageType.Staycation" && !selectedRoomType) ||
        (packageData.type === "packageType.Holiday" && !selectedRoomType))
    ) {
      setErrorMessage("");
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} price_enquiry`,
          category: `details_page`,
        });
      }

      let dateErrorMsg = "";

      if (packageData?.type !== "packageType.Holiday") {
        if (
          new Date(packageData?.travelValidTo) < new Date(endDate) ||
          new Date(packageData?.travelValidTo) < getCheckoutMinDate()!
        ) {
          dateErrorMsg =
            "Check-out is not allowed on selected date. Please change check-out date";
          setErrorMessage(dateErrorMsg);
          itineraryPlanStore.setCalenderSelectedStatus(true);
        }
      }

      if (!dateErrorMsg) {
        itineraryPlanStore.rightContainerData.numRooms = selectedRoomCount;
        itineraryPlanStore.rightContainerData.numChildren =
          selectedChildrenCount;
        itineraryPlanStore.rightContainerData.numAdults = selectedAdultCount;
        itineraryPlanStore.rightContainerData.fromDate = startDate;
        itineraryPlanStore.rightContainerData.toDate = endDate;
        itineraryPlanStore.rightContainerData.childAges = childData?.map(
          (a: any) => parseInt(a.age)
        );
        itineraryPlanStore.setRightContainerData(
          itineraryPlanStore.rightContainerData
        );

        const data: any[] = [];
        let toDate = new Date(startDate).setHours(0, 0, 0, 0);
        let fromDate = new Date(startDate).setHours(0, 0, 0, 0);
        const childAges: number[] = [];

        if (selectedChildrenCount > 0 && childData.length === 0) {
          for (let count = 0; count < selectedChildrenCount; count++) {
            childAges.push(1);
          }
        } else if (selectedChildrenCount > 0 && childData.length !== 0) {
          childData?.forEach((a: any) => {
            childAges.push(parseInt(a.age));
          });

          if (childAges.length !== selectedChildrenCount) {
            for (
              let count = childAges.length;
              count < selectedChildrenCount;
              count++
            ) {
              childAges.push(1);
            }
          }
        }

        if (itineraryPlanStore.stayRoomTypes.length > 0) {
          itineraryPlanStore.stayRoomTypes.forEach((ele: any, i: any) => {
            let dayIndexFrom =
              packageData.itineraryPlans[0].opportunitystays[i]?.dayIndexFrom;
            let dayIndexTo =
              packageData.itineraryPlans[0].opportunitystays[i]?.dayIndexTo;

            fromDate = toDate;
            toDate =
              packageData.type === "packageType.Staycation"
                ? endDate
                : new Date(
                    new Date(fromDate).setDate(
                      new Date(fromDate).getDate() +
                        (dayIndexTo - dayIndexFrom) +
                        1
                    )
                  ).setHours(0, 0, 0, 0);

            data.push({
              propertyId: ele.propertyId,
              roomTypeId: ele.roomId,
              mealPlan: ele.mealPlan ?? "",
              rateClass: ele.rateClass ?? "",
              fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
              toDate: getDateStrInYYYYMMDD(new Date(toDate)),
              numRooms:
                itineraryPlanStore.propertyList.find(
                  (property) =>
                    property.destinationIndex === ele.destinationIndex
                )?.numRooms ?? selectedRoomCount,
              numAdults: selectedAdultCount,
              numChildren: selectedChildrenCount,
              childAges: childAges,
            });
          });
        } else {
          packageData.itineraryPlans[0].opportunitystays.forEach(
            (elem: any, i: any) => {
              fromDate = toDate;
              toDate =
                packageData.type === "packageType.Staycation"
                  ? endDate
                  : new Date(
                      new Date(fromDate).setDate(
                        new Date(fromDate).getDate() +
                          (elem?.dayIndexTo - elem?.dayIndexFrom) +
                          1
                      )
                    ).setHours(0, 0, 0, 0);

              data.push({
                propertyId: elem.propertyId,
                roomTypeId: elem.roomTypeId,
                fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
                toDate: getDateStrInYYYYMMDD(new Date(toDate)),
                numRooms: selectedRoomCount,
                numAdults: selectedAdultCount,
                numChildren: selectedChildrenCount,
                childAges: childAges,
              });
            }
          );
        }

        itineraryPlanStore.setavailabilitypayload(data);
        itineraryPlanStore.setCalenderSelectedStatus(true);
        dispatch(getPackagePricing(data, "", "", packageData.id));
      }
    }
  }, [
    dispatch,
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    userCouponCode,
    childData,
    itineraryPlanStore.stayRoomTypes,
  ]);

  const checkNAN = (data: any) => {
    if (data === undefined || data === "NaN") {
      return "";
    } else if (data !== "NaN") {
      return data;
    }
  };

  useEffect(() => {
    const detailPropertyData = JSON.parse(
      `${sessionStorage.getItem("detailPropertyData")}`
    );

    if (packageData && packageData.id) {
      let fromDate = new Date();
      let toDate = new Date(
        new Date(fromDate).setDate(
          new Date(fromDate).getDate() + inventoryFetchDays
        )
      );

      if (
        detailPropertyData &&
        detailPropertyData.startDate &&
        detailPropertyData.endDate &&
        detailPropertyData.dayDiff &&
        detailPropertyData.calenderInterVal
      ) {
        const a = Math.floor(
          (inventoryFetchDays - detailPropertyData.dayDiff) / 2
        );

        fromDate = new Date(
          new Date(detailPropertyData.startDate.replace(/-/g, "/")).setDate(
            new Date(
              detailPropertyData.startDate.replace(/-/g, "/")
            ).getDate() - a
          )
        );
        if (fromDate < new Date()) {
          fromDate = new Date();
        }
        toDate = new Date(
          new Date(detailPropertyData.endDate.replace(/-/g, "/")).setDate(
            new Date(detailPropertyData.endDate.replace(/-/g, "/")).getDate() +
              a
          )
        );

        fromDate = new Date();
        toDate = new Date(
          new Date(fromDate).setDate(
            new Date(fromDate).getDate() + inventoryFetchDays
          )
        );
      }

      if (fromDate > new Date(packageData.travelValidFrom)) {
        fromDate = new Date();
      } else {
        fromDate = new Date(packageData.travelValidFrom);
      }

      toDate = new Date(
        new Date(fromDate).setDate(
          new Date(fromDate).getDate() + inventoryFetchDays
        )
      );

      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;
      }
    }

    if (detailPropertyData) {
      showIncludeDates
        ? setStartDate(
            new Date(detailPropertyData?.startDate.replace(/-/g, "/"))
          )
        : setStartDate(null);
      showIncludeDates
        ? setEndDate(new Date(detailPropertyData?.endDate.replace(/-/g, "/")))
        : setEndDate(null);

      setSelectedAdultCount(detailPropertyData?.noOfAdults);
      setSelectedChildrenCount(detailPropertyData?.noOfChilds);
      setSelectedRoomCount(detailPropertyData?.noOfRooms);
      setChildData(detailPropertyData.childData);
      setUserCouponCode(detailPropertyData.couponCode);
    }
    // let packageEndDate = new Date();
    let pd = packageData?.numNights;
    if (pd) {
      setPackageDuration(parseInt(pd));
    }
    setFare(packageData?.pricing?.totalFare);

    const urlCouponCode = localStorage.getItem("packageCouponCode");
    if (urlCouponCode) {
      const data = JSON.parse(urlCouponCode);
      if (data.packageId === packageData?.id) {
        setUserCouponCode(data.couponCode);
        //handleApplyCouponCode();
      } else if (stateData?.couponCode) {
        setUserCouponCode(stateData.couponCode);
      } else {
        setUserCouponCode("");
      }
    }
    setErrorMessage("Please select dates");
  }, [packageData, roomDetails.id]);

  useEffect(() => {
    if (urlCouponCode) {
      setUserCouponCode(urlCouponCode);
      //handleApplyCouponCode();
    } else if (stateData?.couponCode) {
      setUserCouponCode(stateData.couponCode);
    } else {
      setUserCouponCode("");
    }
  }, [urlCouponCode]);

  useEffect(() => {
    let maxAdultsAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.numAdults;
    let maxChildrenAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.numChildren;
    let maxPersonsAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.maxGuests;

    setMaxAdultsAllowed(maxAdultsAllowed);
    setMaxChildrenAllowed(maxChildrenAllowed);
    setMaxPersonsAllowed(maxPersonsAllowed);
    setIsAvailabilityChecked(false);
    setLastDateOfInventory(false);
    setIncludeDatesClone([]);
    dispatch(clearPackagePricing());
  }, [packageData, selectedRoomType]);

  useEffect(() => {
    var diff = (endDate - startDate) / 1000 / 60 / 60 / 24;
    setDayDiff(Math.round(diff));

    const startDateObj = _.filter(packageAvailability, function (a: any) {
      return (
        new Date(a.date).setHours(0, 0, 0, 0) ===
        new Date(startDate).setHours(0, 0, 0, 0)
      );
    });
    // startDateObj["minStay"] = 3;

    const stopSellCheck = _.compact(
      _.map(stopSell, function (a) {
        if (
          startDate &&
          endDate &&
          new Date(a).getTime() >= startDate.getTime() &&
          new Date(a).getTime() <= endDate.getTime()
        ) {
          return new Date(a);
        }
      })
    );

    if (_.contains(cta, startDate?.toDateString())) {
      setDateErrorMessage(
        "Check-in is not allowed on selected date. Please change check-in date"
      );
    } else if (_.contains(ctd, endDate?.toDateString())) {
      setDateErrorMessage(
        "Check-out is not allowed on selected date. Please change check-out date"
      );
    } else if (stopSellCheck && stopSellCheck.length > 0) {
      setDateErrorMessage(
        "Booking is not available on selected date (" +
          moment(stopSellCheck[0]).format("DD/MM/YYYY") +
          "). Please change date"
      );
    } else if (
      startDateObj &&
      startDateObj.length > 0 &&
      startDateObj[0].minStay &&
      startDateObj[0].minStay > diff
    ) {
      setDateErrorMessage(
        "Minimum stay for this date is " +
          startDateObj[0].minStay +
          " Nights. Please change date"
      );
    } else {
      setDateErrorMessage("");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let a = Math.ceil(parseInt(selectedAdultCount) / maxAdultsAllowed);
    a = isFinite(a) ? a : 0;
    let b = Math.ceil(parseInt(selectedChildrenCount) / maxChildrenAllowed);
    b = isFinite(b) ? b : 0;
    let c = Math.ceil(
      (parseInt(selectedAdultCount) + parseInt(selectedChildrenCount)) /
        maxPersonsAllowed
    );
    c = isFinite(c) ? c : 0;
    let noOfRooms = Math.max(a, b, c);
    if (noOfRooms > parseInt(selectedRoomCount)) {
      setSelectedRoomCount(noOfRooms);
    }
  }, [selectedAdultCount, selectedChildrenCount]);

  useEffect(() => {
    const checkInDate = startDate?.setHours(5, 30, 0, 0);
    const checkOutDate = endDate?.setHours(5, 30, 0, 0);
    const missingDates = findMissingDates(
      includeDates,
      checkInDate,
      checkOutDate
    );
    if (
      packageData?.type == "packageType.Staycation" &&
      missingDates?.length > 0
    ) {
      handleCheckAvailabilityClick();
    }
    if (startDate?.getTime() < endDate?.getTime() && missingDates?.length < 1) {
      handleCheckAvailabilityClick();
    }
    if (packageData?.type == "packageType.Holiday") {
      handleCheckAvailabilityClick();
    }
  }, [
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    childData,
  ]);

  const { pricing, loader } = useSelector(
    (state: any) => state.PackageDetailsReducer
  );

  useEffect(() => {
    let totalPrice: any = {
      totalFare: 0,
      basicCost: 0,
      extraPAXCost: 0,
    };

    let errorMessage = "";
    if (pricing && Object.keys(pricing).length > 0) {
      for (let propertyPricing of pricing?.propertyPricing) {
        if (errorMessage.length !== 0) {
          break;
        }
        propertyPricing?.room?.forEach((element: any) => {
          if (element?.errorMessage) {
            errorMessage += `Booking cannot proceed with the selected dates/properties. Please change either the dates or the properties. `;
          }
        });
      }

      if (pricing?.priceTotal?.totalFare) {
        totalPrice.totalFare = Math.ceil(pricing?.priceTotal?.totalFare)
          ? Math.ceil(pricing?.priceTotal?.totalFare)
          : "";
      }
    }

    if (errorMessage !== "") {
      itineraryPlanStore.seterrormsgrightcontainer(true);
    } else {
      itineraryPlanStore.seterrormsgrightcontainer(false);
    }
    setTotalPricing(totalPrice);
    itineraryPlanStore.setavailabilityData(pricing);
    itineraryPlanStore.setPriceDetails(totalPrice);
    errorMessage !== "" ? setErrorMessage(errorMessage) : setErrorMessage("");
  }, [pricing]);

  useEffect(() => {}, [itineraryPlanStore.plannedPriceData]);

  const handleContinue = async () => {
    if (
      property &&
      property.startDate &&
      property.endDate &&
      property.noOfRooms &&
      property.noOfAdults
    ) {
      if (
        (userCouponCode && isCouponCodeSuccess) ||
        (!userCouponCode && !isCouponCodeSuccess)
      ) {
        if (isAnalyticsEnabled) {
          ReactGA.event({
            action: "navigate_to_review",
            category: "details_page",
          });
        }
        const perCheckPayLoad: any = [];
        itineraryPlanStore?.availabilitypayload.map((data: any) => {
          const FindRoomDistribution =
            itineraryPlanStore?.availabilityData?.propertyPricing?.find(
              (avaData: any) => avaData.propertyId == data.propertyId
            );
          let newData = { ...data };
          if (newData.rateClass == "") {
            const findRateClassAndRoomType =
              itineraryPlanStore?.stayRoomTypes?.find(
                (avaData: any) => avaData.propertyId == data.propertyId
              );
            newData = {
              ...newData,
              rateClass: findRateClassAndRoomType.rateClass,
            };
          }
          if (newData.mealPlan == "") {
            const findRateClassAndRoomType =
              itineraryPlanStore?.stayRoomTypes?.find(
                (avaData: any) => avaData.propertyId == data.propertyId
              );
            newData = {
              ...newData,
              mealPlan: findRateClassAndRoomType.mealPlan,
            };
          }
          let indexOfBAUratePlan = 0;
          FindRoomDistribution.room[0].priceData.typeWisePriceBreakDown.map(
            (type: any, index: number) => {
              if (type.type == "BAU") {
                indexOfBAUratePlan = index;
              } else {
                indexOfBAUratePlan = 0;
              }
            }
          );
          newData = {
            ...newData,
            roomDistribution:
              FindRoomDistribution.room[0].priceData.typeWisePriceBreakDown[
                indexOfBAUratePlan
              ].roomDistribution,
          };
          perCheckPayLoad.push(newData);
        });

        const headerResponse = await getHeader();
        const response = await experienceApiAxios.post(
          `${GET_PACKAGE_AVAILABILITY}/pre-check/${packageData.id}`,
          perCheckPayLoad,
          {
            headers: headerResponse,
          }
        );
        if (response.status == 200) {
          if (response?.data?.priceTotal) {
            const pricingData = {
              ...itineraryPlanStore.plannedPriceData,
              totalFare: Math.ceil(response?.data?.priceTotal.totalFare)
                ? Math.ceil(response?.data?.priceTotal.totalFare)
                : "",
            };
            itineraryPlanStore.setPriceDetails(pricingData);
          }
        }
        dispatch(clearPackagePricing());
        moment(includeDatesClone[includeDatesClone.length - 1]).format(
          "MM-DD-YYYY"
        );
        property["calenderInterVal"] = includeDatesClone;
        sessionStorage.setItem("detailPropertyData", JSON.stringify(property));
        /*
        passing required data thorough router state object to the review page 
        so that if you refresh the review page data should be persist.       
        */
        property["stayRooms"] = toJS(itineraryPlanStore?.availabilitypayload);
        property["priceObject"] = toJS(itineraryPlanStore?.plannedPriceData);
        // property["availability"] = toJS(itineraryPlanStore?.availabilityData);
        property["availability"] = response.data;
        navigate(`/review`, { state: property });
      } else {
        setUserCouponCodeErrorMessage("Please remove coupon code and proceed");
      }
    } else {
      if (!isAvailabilityChecked) {
        setErrorMessage("Please check availability to proceed");
      } else {
        setErrorMessage("Please check your selections");
      }
    }
  };

  const getCheckoutMinDate = () => {
    if (startDate) {
      let packageEndDate = new Date(startDate.valueOf());
      packageEndDate.setDate(packageEndDate.getDate() + packageDuration);
      return packageEndDate;
    }
  };

  const handleApplyCouponCode = () => {
    if (userCouponCode) {
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} coupon_details`,
          category: `details_page`,
        });
      }

      if (
        property &&
        property.startDate &&
        property.endDate &&
        property.noOfRooms &&
        property.noOfAdults
      ) {
        dispatch(getPackagePricing(property, "", "", ""));
      }
    } else {
      setUserCouponCodeErrorMessage("Please enter Discount Code");
    }
    // }
  };

  const clearUserCouponCode = () => {
    setIsCouponCodeSuccess(false);
    setUserCouponCode("");
    setUserCouponCodeErrorMessage("");
    setUserCouponDetails(false);
    if (
      packageData &&
      packageData.id &&
      startDate &&
      endDate &&
      selectedRoomCount &&
      selectedAdultCount &&
      ((packageData.type === "packageType.Staycation" && !selectedRoomType) ||
        (packageData.type === "packageType.Holiday" && !selectedRoomType))
    ) {
      setErrorMessage("");
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} price_enquiry`,
          category: `details_page`,
        });
      }

      const data: any[] = [];
      packageData.itineraryPlans[0].opportunitystays.forEach(
        (elem: any, i: any) => {
          data.push({
            propertyId: elem.propertyId,
            roomTypeId: elem.roomTypeId,
            fromDate: startDate
              ? countStartDays(startDate, elem.dayIndexFrom)
              : "",
            toDate: endDate ? countEndDays(startDate, elem.dayIndexTo) : "",
            numRooms: selectedRoomCount,
            numAdults: selectedAdultCount,
            numChildren: selectedChildrenCount,
            childAges: childData?.map((a: any) => parseInt(a.age)),
          });
        }
      );

      dispatch(getPackagePricing(data, "", "", packageData.id));
    } else {
      setErrorMessage("Please check your selections");
    }
  };

  const onChildAgeChange = (data: any, newValue: any) => {
    let itemIndex = childData.findIndex((obj: any) => obj.id === data.id);
    let childDataObj = [...childData];
    childDataObj[itemIndex].age = newValue;
    setChildData(childDataObj);
  };

  const openCheckInDatePicker = () => {
    checkInDateRef.current?.setOpen(true);
  };

  const openCheckOutDatePicker = () => {
    checkOutDateRef.current?.setOpen(true);
  };

  const onChangeSelectedChildrenCount = (value: any) => {
    setSelectedChildrenCount(value);
    itineraryPlanStore.rightContainerData.numChildren = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onChangeSelectedAdultCount = (value: any) => {
    setSelectedAdultCount(value);
    itineraryPlanStore.rightContainerData.numAdults = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onchangeStartDate = (value: any) => {
    if (value) {
      let packageEndDate = new Date(value.valueOf());
      if (packageDuration) {
        packageEndDate.setDate(packageEndDate.getDate() + packageDuration);
        setEndDate(packageEndDate);
      }
    }
    setStartDate(value);
    itineraryPlanStore.rightContainerData.fromDate = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };

  const onchangeEndDate = (value: any) => {
    setEndDate(value);
    itineraryPlanStore.rightContainerData.toDate = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };

  const renderTravellersDropDown = () => {
    return (
      <div className="travellers-div">
        <label style={{ flexShrink: 0 }} className="mobile-lable">
          Travellers:
        </label>
        <div className="select-wrapper">
          <div className="select-holder">
            <select
              value={selectedAdultCount}
              onChange={(e) => onChangeSelectedAdultCount(e.target.value)}
              className="select-menu"
            >
              {_.range(adultCount).map((item: any) => {
                return (
                  <option value={item + 1} key={`package_adult_${item}`}>
                    {`${item + 1} ${item === 0 ? " Adult" : " Adults"}`}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="select-holder">
            <select
              value={selectedChildrenCount}
              onChange={(e) => {
                onChangeSelectedChildrenCount(e.target.value);
                if (Number(e.target.value) > 0 && !openTravellerMenu) {
                  setOpenTravellerMenu(!openTravellerMenu);
                }
              }}
              className="select-menu"
            >
              {_.range(childCount + 1).map((item: any) => {
                return (
                  <option value={item} key={`package_children_${item}`}>
                    {`${item} ${
                      item === 0 || item === 1 ? " Child" : " Children"
                    }`}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {childData && childData.length > 0 && (
          <button
            className="expand-btn non-tab"
            onClick={() => setOpenTravellerMenu(!openTravellerMenu)}
          >
            {openTravellerMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(40, 40, 40, 0.9)",
          padding: "15px 0px",
          width: "100%",
        }}
      >
        <div className="section details-sticky-card">
          <div className="brand-calender-wrapper">
            {/* image div */}
            {itineraryPlanStore.isItenaryStaysMount && (
              <div
                style={{ flexShrink: 0, marginTop: "7px" }}
                className="log-container"
              >
                <NavLink to="/">
                  <img
                    src="https://media-prod.raven.voyaah.com/static-content/logo_transparent.png"
                    alt="voyaah-property"
                  />
                </NavLink>
              </div>
            )}
            {/* right side div */}
            <div className="right-side-holder">
              {/* calender div holder */}
              <div
                className="right-side-wrapper"
                style={{
                  // gap:itineraryPlanStore.isItenaryStaysMount ? "0px" : "50px",
                  justifyContent: !itineraryPlanStore.isItenaryStaysMount
                    ? "flex-end"
                    : "space-between",
                }}
              >
                <div className="calender-holder-sticky">
                  <label style={{ flexShrink: 0 }} className="mobile-lable">
                    From-To:
                  </label>
                  <div className="calender-wrapper">
                    <div className="calender-icon-wrapper">
                      {packageData?.type !== "packageType.Holiday" && (
                        <DatePicker
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          minDate={
                            new Date(packageData.travelValidFrom) < new Date()
                              ? addDays(new Date(), 1)
                              : new Date(packageData.travelValidFrom)
                          }
                          maxDate={new Date(packageData.travelValidTo)}
                          className="calender-styles"
                          onChange={(date: Date) => {
                            onchangeStartDate(date);
                          }}
                          closeOnScroll={true}
                          ref={checkInDateRef}
                        />
                      )}
                      {packageData?.type === "packageType.Holiday" && (
                        <DatePicker
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          className="calender-styles"
                          minDate={
                            new Date(packageData.travelValidFrom) < new Date()
                              ? addDays(new Date(), 1)
                              : new Date(packageData.travelValidFrom)
                          }
                          maxDate={addDays(
                            new Date(packageData.travelValidTo),
                            -1 * (packageData.numDays - 1)
                          )}
                          onChange={(date) => onchangeStartDate(date)}
                          closeOnScroll={true}
                          ref={checkInDateRef}
                        />
                      )}
                      <button
                        className="calender-icon"
                        onClick={openCheckInDatePicker}
                      >
                        <CalendarMonthIcon />
                      </button>
                    </div>
                    <div className="calender-icon-wrapper">
                      {packageData?.type !== "packageType.Holiday" && (
                        <DatePicker
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          minDate={getCheckoutMinDate()}
                          maxDate={new Date(packageData.travelValidTo)}
                          onChange={(date: Date) => onchangeEndDate(date)}
                          disabled={!getCheckoutMinDate()}
                          closeOnScroll={true}
                          className="calender-styles"
                          ref={checkOutDateRef}
                        />
                      )}
                      {packageData?.type === "packageType.Holiday" && (
                        <DatePicker
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          minDate={getCheckoutMinDate()}
                          maxDate={new Date(packageData.travelValidTo)}
                          onChange={(date: Date) => onchangeEndDate(date)}
                          disabled={packageData?.type === "packageType.Holiday"}
                          closeOnScroll={true}
                          className="calender-styles"
                          ref={checkOutDateRef}
                          includeDateIntervals={includeDateIntervals}
                          includeDates={includeDates}
                        />
                      )}
                      <button
                        className="calender-icon"
                        onClick={openCheckOutDatePicker}
                      >
                        <CalendarMonthIcon />
                      </button>
                    </div>
                    <button
                      onClick={() => setOpenTravellerMenu(!openTravellerMenu)}
                      className="expand-btn tab"
                    >
                      {openTravellerMenu ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </button>
                  </div>
                </div>
                <div className="responsive-menus">
                  {renderTravellersDropDown()}
                </div>
              </div>
              {openTravellerMenu && (
                <div className="mobile-view">{renderTravellersDropDown()}</div>
              )}
            </div>
          </div>
          {openTravellerMenu && childData.length > 0 && (
            <div
              className={`children-wrapper ${
                itineraryPlanStore.isItenaryStaysMount
                  ? ""
                  : "children-wrapper-initial"
              }`}
            >
              <label style={{ marginTop: "8px" }} className="children-label">
                Children ages:
              </label>
              <div className="select-holder">
                {childData &&
                  childData.length > 0 &&
                  childData.map((item: any, index: number) => {
                    return (
                      <select
                        value={item.age}
                        onChange={(e) => onChildAgeChange(item, e.target.value)}
                        className="select-menu"
                      >
                        {_.range(maxChildAge).map((item: any) => {
                          return (
                            <option
                              value={item + 1}
                              key={`package_children_${item}`}
                            >
                              {`${item + 1} ${item === 0 ? " Year" : " Years"}`}
                            </option>
                          );
                        })}
                      </select>
                    );
                  })}
              </div>
            </div>
          )}
          {!dateErrorMessage && itineraryPlanStore.calenderSelected && (
            <>
              {errorMessage && (
                <div
                  className="form-fields error"
                  style={{ textAlign: "center" }}
                >
                  <p
                    className="error-msg show"
                    style={{ fontSize: "16px", color: "#FF5733" }}
                  >
                    {errorMessage}
                  </p>
                </div>
              )}

              {!errorMessage && itineraryPlanStore.calenderSelected && (
                <div className="pricing-btn-title">
                  <h4 className="pricing-title">
                    {`Total price for this itinerary ${
                      DayDiff
                        ? `(${DayDiff}` +
                          (DayDiff?.toString() === "1" ? " Night)" : " Nights)")
                        : ""
                    }  for ${selectedAdultCount} adults${
                      selectedChildrenCount > 0 ? "," : ":"
                    } ${
                      selectedChildrenCount > 0
                        ? `${selectedChildrenCount} ${
                            selectedChildrenCount?.toString() === "1"
                              ? " Child"
                              : " Children"
                          }: `
                        : ""
                    }`}{" "}
                    <b>
                      {`₹ ${checkNAN(
                        formatMoney(
                          itineraryPlanStore.plannedPriceData.totalFare ?? ""
                        )
                      )}`}
                    </b>
                  </h4>
                  <h3 className="tab-vew-text">
                    {`Total price for this itinerary ${
                      DayDiff
                        ? `(${DayDiff}` +
                          (DayDiff?.toString() === "1" ? " Night)" : " Nights)")
                        : ""
                    }: `}{" "}
                    <b>
                      {` ₹ ${checkNAN(
                        formatMoney(
                          itineraryPlanStore.plannedPriceData.totalFare ?? ""
                        )
                      )}`}
                    </b>
                  </h3>
                  <button onClick={handleContinue}>Continue</button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default StickyHeaderNew;
