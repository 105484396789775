import _ from "underscore";
import * as url from "./url_helper";
import {
  experienceApiGet,
  experienceApiPost,
  experienceApiPut,
} from "./api_helper";
import moment from "moment";
import { ect } from "./utils";

export const getTestEnabled = () => {
  const isTestEnabled: string = process.env.REACT_APP_TEST_ENABLED!;
  if (isTestEnabled === "Y") {
    return true;
  }

  return false;
};

export const getAnalyticsEnabled = () => {
  const localStorageData = localStorage.getItem("voyaahCustomerConsent");
  if (localStorageData) {
    const isTestEnabled: string = process.env.REACT_APP_GA_ENABLED!;
    if (isTestEnabled === "Y") {
      return true;
    }
  }

  return false;
};

export const getHeader = (): Promise<any> => {
  const localStorageData = localStorage.getItem("voyaahCustomerTracker");
  let customerProfileId = "";
  if (localStorageData) {
    customerProfileId = JSON.parse(localStorageData!).id;
  }

  return new Promise((resolve, reject) => {
    const clientIp = sessionStorage.getItem("clientIp");
    const email = sessionStorage.getItem("email");
    const partnerUserData = sessionStorage.getItem("partnerUser");
    const channelOfSale = process.env.REACT_APP_CHANNEL_OF_SALE;
    var dataObj = {};
    if (email) {
      dataObj = {
        emailId: email,
        loggedIn: true,
        channelOfSale: channelOfSale,
      };
    } else {
      dataObj = { loggedIn: false, email: email, channelOfSale: channelOfSale };
    }
    if (channelOfSale === "channelOfSale.ClubVistara") {
      dataObj = {
        ...dataObj,
        subPartnerId: JSON.parse(partnerUserData as string)?.channelOfSale?.[
          "channelOfSale.ClubVistara"
        ],
      };
    }
    try {
      if (clientIp) {
        ect(clientIp, dataObj).then((response) => {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: response,
          };
          resolve(headers);
        });
      } else {
        const domain = process.env.REACT_APP_DOMAIN;
        experienceApiGet(
          url.GET_HOME_CONTENT +
            "?domain=" +
            domain +
            "&customerProfileId=" +
            customerProfileId
        ).then((homeData) => {
          if (homeData && homeData.clientIP) {
            sessionStorage.setItem("clientIp", homeData.clientIP);
            ect(homeData.clientIP, dataObj).then((response) => {
              const headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: response,
              };
              resolve(headers);
            });
          } else {
            reject({ message: "ClientIP not found." });
          }
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getHeaderForProfileUpdate = (emailId: string): Promise<any> => {
  const localStorageData = localStorage.getItem("voyaahCustomerTracker");
  let customerProfileId = "";
  if (localStorageData) {
    customerProfileId = JSON.parse(localStorageData!).id;
  }

  return new Promise((resolve, reject) => {
    const clientIp = sessionStorage.getItem("clientIp");
    const email = sessionStorage.getItem("email") ?? emailId;
    const partnerUserData = sessionStorage.getItem("partnerUser");
    const channelOfSale = process.env.REACT_APP_CHANNEL_OF_SALE;
    var dataObj = {};
    if (email) {
      dataObj = {
        emailId: email,
        loggedIn: true,
        channelOfSale: channelOfSale,
      };
    } else {
      dataObj = { loggedIn: false, email: email, channelOfSale: channelOfSale };
    }
    if (channelOfSale === "channelOfSale.ClubVistara") {
      dataObj = {
        ...dataObj,
        subPartnerId: JSON.parse(partnerUserData as string)?.channelOfSale?.[
          "channelOfSale.ClubVistara"
        ],
      };
    }
    try {
      if (clientIp) {
        ect(clientIp, dataObj).then((response) => {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: response,
          };
          resolve(headers);
        });
      } else {
        const domain = process.env.REACT_APP_DOMAIN;
        experienceApiGet(
          url.GET_HOME_CONTENT +
            "?domain=" +
            domain +
            "&customerProfileId=" +
            customerProfileId
        ).then((homeData) => {
          if (homeData && homeData.clientIP) {
            sessionStorage.setItem("clientIp", homeData.clientIP);
            ect(homeData.clientIP, dataObj).then((response) => {
              const headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: response,
              };
              resolve(headers);
            });
          } else {
            reject({ message: "ClientIP not found." });
          }
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getHeaderForChannelOfSale = (
  channelOfSaleForRequest: string,
  customerProfileId: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const clientIp = sessionStorage.getItem("clientIp");
    const email = sessionStorage.getItem("email");
    const partnerUserData = sessionStorage.getItem("partnerUser");
    const channelOfSale =
      channelOfSaleForRequest ?? process.env.REACT_APP_CHANNEL_OF_SALE;
    var dataObj = {};
    if (email) {
      dataObj = {
        emailId: email,
        loggedIn: true,
        channelOfSale: channelOfSale,
      };
    } else {
      dataObj = { loggedIn: false, email: email, channelOfSale: channelOfSale };
    }
    if (channelOfSale === "channelOfSale.ClubVistara") {
      dataObj = {
        ...dataObj,
        subPartnerId: JSON.parse(partnerUserData as string)?.channelOfSale?.[
          "channelOfSale.ClubVistara"
        ],
      };
    }
    try {
      if (clientIp) {
        ect(clientIp, dataObj).then((response) => {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: response,
          };
          resolve(headers);
        });
      } else {
        const domain = process.env.REACT_APP_DOMAIN;
        experienceApiGet(
          url.GET_HOME_CONTENT +
            "?domain=" +
            domain +
            "&customerProfileId=" +
            customerProfileId
        ).then((homeData) => {
          if (homeData && homeData.clientIP) {
            sessionStorage.setItem("clientIp", homeData.clientIP);
            ect(homeData.clientIP, dataObj).then((response) => {
              const headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: response,
              };
              resolve(headers);
            });
          } else {
            reject({ message: "ClientIP not found." });
          }
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getHomeData = (customerProfileId: string, accountId: string) => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(
    url.GET_HOME_CONTENT +
      "?domain=" +
      domain +
      "&customerProfileId=" +
      customerProfileId +
      "&accountId=" +
      accountId
  );
};

export const getTermsAndConditionData = (
  pageurl: string,
  customerProfileId: string,
  accountId: string
) => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(
    url.GET_CMS_DATA +
      "?pageUrl=" +
      pageurl +
      "&domain=" +
      domain +
      "&customerProfileId=" +
      customerProfileId +
      "&accountId=" +
      accountId
  );
};

export const getAboutUsData = (
  pageurl: string,
  customerProfileId: string,
  accountId: string
) => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(
    url.GET_CMS_DATA +
      "?pageUrl=" +
      pageurl +
      "&domain=" +
      domain +
      "&customerProfileId=" +
      customerProfileId +
      "&accountId=" +
      accountId
  );
};

export const getGroupBookingData = (
  pageurl: string,
  customerProfileId: string,
  accountId: string
) => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(
    url.GET_CMS_DATA +
      "?pageUrl=" +
      pageurl +
      "&domain=" +
      domain +
      "&customerProfileId=" +
      customerProfileId +
      "&accountId=" +
      accountId
  );
};

export const getListingPageData = (
  pageurl: string,
  customerProfileId: string,
  accountId: string
) => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(
    url.GET_CMS_DATA +
      "?pageUrl=" +
      pageurl +
      "&domain=" +
      domain +
      "&customerProfileId=" +
      customerProfileId +
      "&accountId=" +
      accountId
  );
};

export const createCustomerProfile = (data: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiPost(url.SET_CUSTOMER_TRACKING, data, {
            headers: response,
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCustomerProfile = (data: any) => {
  const requestObj = {
    accountId: data.accountId,
    customerProfileId: data.customerProfileId,
  };
  return new Promise((resolve, reject) => {
    getHeaderForProfileUpdate(data?.email)
      .then((response) => {
        resolve(
          experienceApiPost(url.CUSTOMER_PROFILE, requestObj, {
            headers: response,
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveCustomerInterests = (data: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiPost(url.CUSTOMER_INTERESTS, data, {
            headers: response,
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCustomerInterests = (
  customerProfileId: string,
  accountId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            url.CUSTOMER_INTERESTS +
              "?customerProfileId=" +
              customerProfileId +
              "&accountId=" +
              accountId,
            {
              headers: response,
            }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDestinations = (searchKey: string) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            url.DESTINATIONS + "?pageSize=40&currentPage=1&search=" + searchKey,
            {
              headers: response,
            }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPackageSummary = (data: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(url.GET_PACKAGE_LISTING + "?" + data, {
            headers: response,
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPackageDetails = (
  id: any,
  customerProfileId: string,
  accountId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            url.GET_PACKAGE_LISTING +
              "/" +
              id +
              "?customerProfileId=" +
              customerProfileId +
              "&accountId=" +
              accountId,
            {
              headers: response,
            }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPackagePricing = (
  data: any,
  customerProfileId: string,
  accountId: string,
  packageId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        // const childAges = _.pluck(data?.childData, "age") || [];
        // const selectedRoomTypeName = data?.roomType;
        // const itineraryRoomType = _.pluck(
        //   data?.packageData?.itinerary?.[0]?.roomTypes,
        //   "roomType"
        // );
        // const selectedRoomType = _.findWhere(itineraryRoomType, {
        //   name: selectedRoomTypeName,
        // });
        // const params: any = {
        //   roomTypeId: selectedRoomType?.id,
        //   checkinDate: moment(data?.startDate.replace(/-/g, "/")).format(
        //     "YYYY-MM-DD"
        //   ),
        //   checkoutDate: moment(data?.endDate.replace(/-/g, "/")).format(
        //     "YYYY-MM-DD"
        //   ),
        //   noOfRooms: data?.noOfRooms,
        //   numAdults: data?.noOfAdults,
        //   numChildren: data?.noOfChilds,
        //   childAges: childAges,
        // };
        // if (data?.couponCode) {
        //   params["couponCode"] = data?.couponCode;
        // }
        resolve(
          experienceApiPost(
            url.GET_PACKAGE_AVAILABILITY +
              "/" +
              packageId +
              "?customerProfileId=" +
              customerProfileId +
              "&accountId=" +
              accountId,
            data,
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBookingDetails = (
  data: any,
  couponCode: any,
  customerProfileId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        var bookingDetailsUrl = url.GET_BOOKING_DETAILS;
        if (couponCode) {
          bookingDetailsUrl =
            bookingDetailsUrl +
            "?couponCode=" +
            couponCode +
            "&customerProfileId=" +
            customerProfileId;
        } else {
          bookingDetailsUrl =
            bookingDetailsUrl + "?customerProfileId=" + customerProfileId;
        }
        resolve(
          experienceApiPost(bookingDetailsUrl, data, { headers: response })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBookingList = (pageNumber: number) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            `${url.GET_BOOKING_LIST}?currentPage=${pageNumber}`,
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getItineraryList = (pageNumber: number) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            `${url.GET_ITINERARY_LIST}?currentPage=${pageNumber}`,
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getContactDetails = (data: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiPost(url.CONTACT_SUPPORT, data, { headers: response })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadBookingDocument = (data: any, orderId: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        experienceApiPost(
          url.UPDATE_BOOKING_STATUS + orderId + "/travellerDocuments",
          data,
          { headers: response }
        ).then((data) => {
          resolve(data);
        });
        // resolve(experienceApiPost(url.UPDATE_BOOKING_STATUS + orderId + "/travellerDocuments", data, { headers: response }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPackageAvailability = (
  data: any,
  customerProfileId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            url.GET_PACKAGE_AVAILABILITY +
              "/" +
              data.packageId +
              // "?roomTypeId=" +
              // data.roomTypeId +
              "?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate +
              "&customerProfileId=" +
              customerProfileId,
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getHolidayPackageAvailabilty = (
  data: any,
  customerProfileId: string
) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiGet(
            url.GET_PACKAGE_AVAILABILITY +
              "/" +
              data.packageId +
              "?propertyId=" +
              data.propertyId +
              "&fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate +
              "&customerProfileId=" +
              customerProfileId,
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getClientIpAddress = () => {
  const domain = process.env.REACT_APP_DOMAIN;
  return experienceApiGet(url.GET_HOME_CONTENT + "?domain=" + domain);
};

export const updateBookingStatus = (data: any) => {
  return new Promise((resolve, reject) => {
    getHeader()
      .then((response) => {
        resolve(
          experienceApiPut(
            url.UPDATE_BOOKING_STATUS +
              data.bookingId +
              "/status?changedStatus=" +
              data.changedStatus,
            "",
            { headers: response }
          )
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPaymentDetails = (
  bookingId: any,
  channelOfSale: string,
  customerProfileId: string
) => {
  return new Promise((resolve, reject) => {
    getHeaderForChannelOfSale(channelOfSale, customerProfileId)
      .then((response) => {
        resolve(
          experienceApiGet(url.GET_BOOKING_DETAILS + "/" + bookingId, {
            headers: response,
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};
